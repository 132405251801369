import React, { ReactChild, ReactChildren } from "react";
import { Link } from "gatsby"
import Row, { RowProps } from "../Row/Row";
import Col, { ColProps } from "../Col/Col";
import "./Footer.scss"

interface FooterProps {
  className?: string
  id?: string
  colFlex?: boolean
  reversed?: boolean
}

const Footer = ({
  className,
  id,
  colFlex,
  reversed
} : FooterProps) => {

  return (
    <footer className="footer temporary">
      <div className="section__wrap">
        <Row>
          <Col size="25">
            <Link to="/">Home</Link>
            <Link to="/our-story">Our Story</Link>
            <Link to="/programs">Programs</Link>
            <Link to="/success-stories">Success Stories</Link>
            <Link to="/resources">Resources</Link>
          </Col>
          <Col size="25">
            <a href="/">Facebook</a>
            <a href="/">Instagram</a>
            <a href="/">YouTube</a>
            <a href="/">Twitter</a>
          </Col>
          <Col size="25">
            <Link to="/terms-and-conditions">Terms & conditions</Link>
            <Link to="/privacy-policy">Privacy policy</Link>
            <Link to="/disclaimer">Disclaimer</Link>
            <Link to="/return-exchange">Return exchange</Link>
          </Col>
          <Col size="25">
          </Col>
        </Row>
      </div>
    </footer>
  )
};

export default Footer;

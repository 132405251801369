import React, { ReactChild, ReactChildren } from "react";
import "./Button.scss"

interface ButtonProps {
  size?: string
  type?: string
  link?: string
  text?: string
}
const Button = ({
  size,
  type,
  link,
  text
} : ButtonProps) => {

  return (
    <a className={`${size ? size : "medium"} ${type} button`} href={link}>{text}</a>
  )
};

export default Button;

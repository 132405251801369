import React, { ReactChild, ReactChildren } from "react";
import Col, { ColProps } from "../Col/Col";
import "./Row.scss"
import Title from '@/components/Title/Title';

interface RowProps {
  className?: string
  id?: string
  children?: ReactChild | ReactChild[]
  colFlex?: boolean
  reversed?: boolean
}

const Row = ({
  className,
  id,
  children,
  colFlex,
  vCentered,
  reversed
} : RowProps) => {

    let colFlexFlag = "";
    let vCenteredFlag = "";
    let orderReversed = "";

    if (colFlex) {
      colFlexFlag = "colFlex";
    }

    if (vCentered) {
      vCenteredFlag = "vCentered";
    }

    if (reversed) {
      orderReversed = "reversed";
    }

  return (
      <div className={`${colFlexFlag} ${className} ${vCenteredFlag} ${orderReversed} row`}>
        {children}
      </div>
  )
};

export default Row;
